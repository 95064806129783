/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.content {
    background-color: #fff !important;
    padding: 20px !important;
    border-radius: 8px !important;
    opacity: 0.9 !important;
}

.mat-paginator .mat-paginator-container {
    justify-content: end !important;
}

/* Remove asterisk from required form field placeholder */
span.mat-placeholder-required.mat-form-field-required-marker.ng-star-inserted {
    display: none !important;
}

.mat-tab-group {
    .mat-tab-header {
        .mat-tab-label-container {
            z-index: 0 !important;
            padding: 2px 0px !important;

            .mat-tab-list {
                .mat-tab-labels {
                    .mat-tab-label {
                        //  min-width: 0 !important;
                        //  height: 40px !important;
                        padding: 0 20px !important;
                        border-radius: 3px !important;
                        //  --tw-text-opacity: 1;
                        color: rgba(var(--fuse-text-secondary-rgb),
                                var(--tw-text-opacity));
                        //  border: .5px solid rgb(224, 221, 221) !important;
                        background-color: #edeaea !important;

                        &.mat-tab-label-active {
                            background-color: #c9f0ff !important;
                            //  color: white !important;
                        }

                        +.mat-tab-label {
                            margin-left: 7px;
                        }
                    }
                }
            }
        }
    }
}

// .mat-tab-group
//     .mat-tab-header
//     .mat-tab-label-container
//     .mat-tab-list
//     .mat-tab-labels
//     .mat-tab-label.mat-tab-label-active {
//     background-color: #52aace !important;
//     color: white !important;
// }

.back-button {
    border-radius: 6px;
    background-color: #52aace !important;
    color: white !important;
    padding: 0.5rem;
    font-size: medium;
}

.btn-secondary {
    background-color: lightgrey;
}

textarea {
    border: 1px solid lightgray !important;
    padding: 2px !important;
}

th {
    color: rgb(52, 52, 52) !important;
}

// .mat-dialog-container {
//     padding: 0 !important;
// }
.header-warn {
    background: #bc3d1a;
    color: white;
    font-size: 30px;
}

.header-info {
    background: #1a58bc;
    color: white;
    font-size: 30px;
}

.header-success {
    background: #1dbc1a;
    color: white;
    font-size: 30px;
}

.page-sub-header {
    font-size: 1.125rem !important;
    color: #6f7682 !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

// .completeness-pending {
//     box-sizing: border-box;
//     position: relative;
//     display: block;
//     transform: scale(var(--ggs, 1));
//     width: 21px;
//     height: 21px;
//     min-width: 21px;
//     border: 2px solid rgb(85, 84, 84);
//     border-radius: 50%;
//     color: rgb(255, 255, 255);
//     background-color: rgb(255, 255, 255);
// }

.completeness-done {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 22px;
    height: 22px;
    min-width: 22px;
    border: 2px solid green;
    border-radius: 100px;
    color: rgb(255, 255, 255);
    background-color: green;
}

.completeness-done::after {
    content: "";
    color: rgb(255, 255, 255);
    display: block;
    box-sizing: border-box;
    position: absolute;
    left: 3px;
    top: -1px;
    width: 6px;
    height: 10px;
    border-color: currentColor;
    border-width: 0 2px 2px 0;
    border-style: solid;
    transform-origin: bottom left;
    transform: rotate(45deg);
    background: green;
}

.completeness-danger:before {
    content: "X";
    display: inline-block;
    font-weight: bold;
    text-align: center;

    line-height: 1.8ex;
    width: 22px;
    height: 22px;
    min-width: 22px;
    border: 2px solid;
    border-radius: 100px;
    //margin-right: 4px;
    //padding: 2px;
    color: rgb(255, 255, 255);
    background: red;
    border: 2px solid red;
    text-decoration: none;
}

.completeness-warn:before {
    content: "?";
    display: inline-block;
    font-weight: bold;
    text-align: center;

    line-height: 1.8ex;
    width: 22px;
    height: 22px;
    min-width: 22px;
    border: 2px solid;
    border-radius: 100px;
    //margin-right: 4px;
    padding: 2px;
    color: white;
    background: yellow;
    border: 2px solid yellow;
    text-decoration: none;
}

.choose-button {
    align-self: flex-end;
    appearance: none;
    background-color: #fafbfc;
    border: 1px solid rgba(27, 31, 35, 0.15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
        rgba(255, 255, 255, 0.25) 0 1px 0 inset;
    box-sizing: border-box;
    color: #24292e;
    cursor: pointer;
    display: inline-block;

    list-style: none;
    padding: 6px 16px;
    position: relative;
    transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    word-wrap: break-word;
}

.evaluation-block {
    border: 1px rgb(8, 8, 8) solid;
    border-radius: 3px;
    padding: 4px;
    margin-top: 6px;
    margin-left: 2px;
    background-color: white;
    margin-bottom: 6px;
}

textarea {
    background-color: white !important;
}

.material-icons.color_green {
    color: green;
}

.material-icons.color_red {
    color: red;
}

.material-icons.color_yellow {
    color: #a9a900;
}

.completeness-na {
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50%;
    font-size: xx-small;
    color: #fff;
    text-align: center;
    margin-left: 2px;
    background: #929292;
}

.red-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;

}

.btn-primary {
    background-color: #2f6ca1;
}

.mat-select-panel-wrap {
    .mat-select-panel {
        max-width: max-content !important;
    }
}